<template>


  <div id="contactus" class="w-100 "> 

 
<b-container fluid>
  <b-row>
    <b-col class="bg-image"></b-col>

    <div class="w-100"></div>
       <b-container>
    <b-col class="mt-5 text-center"><p>This section is under construction.</p></b-col></b-container>

  </b-row>
</b-container> 
 
</div>

 
 
</template>

<script>
// @ is an alias to /src
 
 

export default {
  name: 'contactus',
  components: {
 
   
  }
}
</script>


<style scoped lang="scss">
.mtcustom{
   display:absolute
}


.bg-image{
   background-image: url(https://nocbbo.com/images/24b.jpg);
background-repeat: no-repeat;
background-position: center center;
background-attachment: scroll;
background-size: cover;
min-height: 350px;
}
</style>
